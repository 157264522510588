<template>
  <div class="block">
    <div class="block-content container">
      <div class="block-content-head">
        <div class="block-content-head-top">
          <h3 class="block-content-head-top-title">THE ORCA'S SET OF TECHNOLOGIES FOR SOFTWARE DEVELOPMENT</h3>
          <img
            class="block-content-head-top-logo"
            src="@/assets/images/logo-black.svg"
            alt="">
        </div>
        <p class="block-content-head-subtitle">
          Our developers employ the latest technologies, frameworks, and components to create solutions that combine a strong technical back-end with easy to use modern design.
        </p>
      </div>
      <div class="block-content-slider">
        <ImageSlider />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
</script>

<style lang="scss" scoped>
.block {
  position: relative;
  height: auto;
  width: 100%;
  background: linear-gradient(90.38deg,#dee9ff -88.49%,#fff 82.75%);
  padding-bottom: get-vw(70px);
  padding-top: get-vw(30px);
  &-content {
    //height: get-vw(550px);
    background: linear-gradient(180deg,#dee9ff,#fff);
    box-shadow: 0 0 get-vw(50px) rgb(118 129 138 / 75%);
    border-radius: get-vw(20px);
    overflow: hidden;

    &-head {
      padding-left: get-vw(60px);
      padding-right: get-vw(60px);
      padding-top: get-vw(50px);
      &-top {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        &-title {
          color: #000;
          font-size: get-vw(45px);
          max-width: get-vw(600px);
          line-height: get-vw(45px);
        }
        &-logo {
          width: get-vw(250px);
          height: auto;
        }
      }
      &-subtitle {
        font-family: "Inter",sans-serif;
        padding-right: get-vw(11px);
        padding-top: get-vw(20px);
        font-size: get-vw(21px);
      }
    }

  }
}
</style>
